.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.responsive-min(@minWidth; @rules) {
  @media only screen and (min-width: @minWidth) {
    @rules();
  }
}