@import '../../../antd-config';

.meeting-indicator{
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: @gray1;
    padding: 1rem;
    font-family: @font-family;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
        font-size: x-large;
        text-align: center;
    }

    a{
        background-color: @primary-color;
        color: white;
        padding: .5rem 4rem;
        border-radius: 8px;
        margin-inline: auto;
        font-size: 1.2rem;
    }
}
@primary-color: #31bdbf;@dark-blue: #252c43;@dark-blue2: #334557;@dark-blue3: #2d3f4f;@dark-blue4: #212529;@dark-blue5: #334657;@gray1: #f0f0f1;@gray2: #e9e9ec;@gray3: #f1f1f2;@gray4: #d2d2d2;@gray5: #5d5d60;@gray6: #e8e8e8;@gray7: #f0f0f2;@gray8: #6a747d;@gray9: #69767f;@gray10: #848e97;@gray11: #334559;@gray12: #e9e8ed;@gray13: #54545c;@gray14: #707c89;@gray15: #f7f7f8;@gray16: #899299;@gray17: #52555d;@gray18: #e7e7ea;@gray19: #8f99a3;@gray20: #f7f7f9;@gray21: #7e8790;@gray22: #e2e1e6;@gray23: #717179;@gray24: #a9a9ab;@gray25: #efeff1;@red: #f06269;@font-family: 'Poppins', sans-serif;