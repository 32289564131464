@import "../../../../../antd-config.less";
@import "../../../../../assets/less/mixins.less";

.course-header {
  margin-bottom: 20px;
}

.super-stage-container {
  margin-bottom: 20px !important;

  .super-stage {
    .thumb-container {
      background-color: @black;
      overflow: hidden;
      position: relative;
  
      img {
        display: block;
        height: 250px;
        object-fit: cover;
        object-position: center;
        opacity: 0.9;
        width: 100%;
      }
    }

    .content {
      padding: 20px;
      background-color: @gray3;

      h4 {
        color: @black;
      }
  
      .responsive-min(@screen-xl-min, {
        padding: 30px;
      });

      .summary {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .rating {
      margin-bottom: 10px;
    }

    .btns-container {
      button {
        margin-bottom: 10px;
      }

      .ant-col:nth-child(1) {
        button {
          padding-inline: 0;
        }
      }
    }
  }
}


.reviews-panel {
  background-color: @white;

  .review-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }

  .reviews-container {
    max-height: 70dvh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: @gray15;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 10px;
    }
  }

  .review-item {
    background-color: @gray15;
    margin-bottom: 5px;
    padding: 15px 25px;
  }
}

@primary-color: #31bdbf;@dark-blue: #252c43;@dark-blue2: #334557;@dark-blue3: #2d3f4f;@dark-blue4: #212529;@dark-blue5: #334657;@gray1: #f0f0f1;@gray2: #e9e9ec;@gray3: #f1f1f2;@gray4: #d2d2d2;@gray5: #5d5d60;@gray6: #e8e8e8;@gray7: #f0f0f2;@gray8: #6a747d;@gray9: #69767f;@gray10: #848e97;@gray11: #334559;@gray12: #e9e8ed;@gray13: #54545c;@gray14: #707c89;@gray15: #f7f7f8;@gray16: #899299;@gray17: #52555d;@gray18: #e7e7ea;@gray19: #8f99a3;@gray20: #f7f7f9;@gray21: #7e8790;@gray22: #e2e1e6;@gray23: #717179;@gray24: #a9a9ab;@gray25: #efeff1;@red: #f06269;@font-family: 'Poppins', sans-serif;