@import "../../../antd-config.less";
@import "../../../assets/less/mixins.less";

.course-header {
  margin-bottom: 20px;
}

.super-stage-container {
  margin-bottom: 20px !important;
}

.super-stage-item {
  background-color: @gray15;
  cursor: pointer;
  
  .img-container {
    background-color: @black;
    max-height: 400px;
    
    img {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.9;
      width: 100%;
    }
  }

  .content {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 400px;
    overflow-y: scroll;
    scrollbar-width: none;

    .responsive-min(@screen-xl-min, {
      padding: 30px;
    });
  }

  .rating {
    margin-bottom: 10px;
  }

  button {
    background-color: @dark-blue2;
    border-color: @dark-blue2;
    font-size: 1.143em;
    width: 100%;

    .responsive-min(@screen-xl-min, {
      width: auto;
    });
  }

  .mobile {
    .responsive-min(@screen-xl-min, {
      display: none;
    });
  }
}

@primary-color: #31bdbf;@dark-blue: #252c43;@dark-blue2: #334557;@dark-blue3: #2d3f4f;@dark-blue4: #212529;@dark-blue5: #334657;@gray1: #f0f0f1;@gray2: #e9e9ec;@gray3: #f1f1f2;@gray4: #d2d2d2;@gray5: #5d5d60;@gray6: #e8e8e8;@gray7: #f0f0f2;@gray8: #6a747d;@gray9: #69767f;@gray10: #848e97;@gray11: #334559;@gray12: #e9e8ed;@gray13: #54545c;@gray14: #707c89;@gray15: #f7f7f8;@gray16: #899299;@gray17: #52555d;@gray18: #e7e7ea;@gray19: #8f99a3;@gray20: #f7f7f9;@gray21: #7e8790;@gray22: #e2e1e6;@gray23: #717179;@gray24: #a9a9ab;@gray25: #efeff1;@red: #f06269;@font-family: 'Poppins', sans-serif;