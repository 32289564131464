@import "../../../antd-config";
@import "../../../assets/less/mixins";

h2 {
  color: @dark-blue2;
  display: inline-block;
  font-size: 1.875em;
  font-weight: 600;
}

h3 {
  color: @dark-blue2;
  display: inline-block;
  font-size: 1.429em;
  font-weight: 600;
  line-height: 1.115em;
}

h4 {
  color: @gray14;
  display: inline-block;
  font-size: 1.071em;
}

.partial-underline {
  margin-bottom: 40px;
  position: relative;
  
  .responsive-min(@screen-md-min, {
    margin-bottom: 80px;
  });

  &::after {
    content: '';
    background-color: @primary-color;
    bottom: -10px;
    display: block;
    height: 5px;
    position: absolute;
    width: 60px;
  }
}

.center {
  text-align: center;
  &:after {
    left: 50%;
    transform: translateX(-50%);
  }
}

.inverted {
  h2 {
    color: @white;
  }
}
@primary-color: #31bdbf;@dark-blue: #252c43;@dark-blue2: #334557;@dark-blue3: #2d3f4f;@dark-blue4: #212529;@dark-blue5: #334657;@gray1: #f0f0f1;@gray2: #e9e9ec;@gray3: #f1f1f2;@gray4: #d2d2d2;@gray5: #5d5d60;@gray6: #e8e8e8;@gray7: #f0f0f2;@gray8: #6a747d;@gray9: #69767f;@gray10: #848e97;@gray11: #334559;@gray12: #e9e8ed;@gray13: #54545c;@gray14: #707c89;@gray15: #f7f7f8;@gray16: #899299;@gray17: #52555d;@gray18: #e7e7ea;@gray19: #8f99a3;@gray20: #f7f7f9;@gray21: #7e8790;@gray22: #e2e1e6;@gray23: #717179;@gray24: #a9a9ab;@gray25: #efeff1;@red: #f06269;@font-family: 'Poppins', sans-serif;