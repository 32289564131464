@import "../../antd-config";
@import "../../assets/less/mixins";

.about-us-section {
  background-color: @gray1;
  padding-bottom: 60px;

  .responsive-min(@screen-sm-min, {
    padding-bottom: 80px;
  });

  &__content {
    align-items: stretch;
    background-color: @white;
    border-radius: 5px;
    padding: 8px;

    .responsive-min(@screen-sm-min, {
      padding: 16px;
    });
  }

  img {
    width: 100%;
  }

  &__desc {
    background-color: @gray1;
    height: 100%;
    padding: 20px;
    text-align: center;

    a{
      margin-top: 2rem;
      font-size: 1.286em;
      font-weight: 500;
      height: auto;
      padding: 2px 16px;
    }

    .responsive-min(@screen-sm-min, {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px;
      text-align: left;
    });
  }
}

@primary-color: #31bdbf;@dark-blue: #252c43;@dark-blue2: #334557;@dark-blue3: #2d3f4f;@dark-blue4: #212529;@dark-blue5: #334657;@gray1: #f0f0f1;@gray2: #e9e9ec;@gray3: #f1f1f2;@gray4: #d2d2d2;@gray5: #5d5d60;@gray6: #e8e8e8;@gray7: #f0f0f2;@gray8: #6a747d;@gray9: #69767f;@gray10: #848e97;@gray11: #334559;@gray12: #e9e8ed;@gray13: #54545c;@gray14: #707c89;@gray15: #f7f7f8;@gray16: #899299;@gray17: #52555d;@gray18: #e7e7ea;@gray19: #8f99a3;@gray20: #f7f7f9;@gray21: #7e8790;@gray22: #e2e1e6;@gray23: #717179;@gray24: #a9a9ab;@gray25: #efeff1;@red: #f06269;@font-family: 'Poppins', sans-serif;