@import url(https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap);
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container {
  margin: auto;
  padding: 0 16px;
  width: 100%;
}
.section-container {
  padding: 20px 0;
}
@media only screen and (min-width: 992px) {
  .section-container {
    padding: 80px 0;
  }
}
@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.input-label {
  color: #252c43;
  display: inline-block;
  font-size: 1.115em;
  font-weight: 500;
  margin-bottom: 3px;
}
.form-item .error-message {
  color: #f5222d;
  display: inline-block;
  padding-top: 5px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.input {
  padding: 20px;
}
.input.has-error {
  box-shadow: 0 0 2px 1px #f5222d;
  color: #f5222d;
}
.input.has-error:hover,
.input.has-error:focus {
  border-color: #f5222d;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.form-item {
  margin-bottom: 20px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.button {
  font-size: 1.286em;
  font-weight: 500;
  height: auto;
  padding: 2px 16px;
}
.invert {
  color: #fff;
}
.invert:hover {
  color: #31bdbf;
}
.full-width {
  width: 100%;
}
.text-only,
.text-only:hover,
.text-only:active,
.text-only:visited,
.text-only:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #69767f;
  height: auto;
  outline: none;
  padding: 0;
}
.underline {
  border-bottom: 1px solid #69767f !important;
  border-radius: 0;
}
.small {
  font-size: 1.143em;
}
.large {
  padding: 6px 25px;
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.0821fff1.eot);
  src: url(/static/media/fontello.0821fff1.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.cafe5ef4.woff2) format('woff2'),
       url(/static/media/fontello.d3067fa7.woff) format('woff'),
       url(/static/media/fontello.3ab83495.ttf) format('truetype'),
       url(/static/media/fontello.81c3415e.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?37495438#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-down-open-big:before { content: '\e800'; } /* '' */
.icon-star:before { content: '\e801'; } /* '' */
.icon-ok-circled2:before { content: '\e802'; } /* '' */
.icon-right-small:before { content: '\e803'; } /* '' */
.icon-address:before { content: '\e804'; } /* '' */
.icon-charted-acc:before { content: '\e805'; } /* '' */
.icon-contact:before { content: '\e806'; } /* '' */
.icon-courses:before { content: '\e807'; } /* '' */
.icon-left-small:before { content: '\e808'; } /* '' */
.icon-easy-payment-plan:before { content: '\e809'; } /* '' */
.icon-file-excel:before { content: '\e80a'; } /* '' */
.icon-expert-lecturers:before { content: '\e80b'; } /* '' */
.icon-facebook:before { content: '\e80c'; } /* '' */
.icon-graduation-hat:before { content: '\e80d'; } /* '' */
.icon-file-jpeg:before { content: '\e80e'; } /* '' */
.icon-lecturers:before { content: '\e80f'; } /* '' */
.icon-file-pdf:before { content: '\e810'; } /* '' */
.icon-popular-learning-path:before { content: '\e811'; } /* '' */
.icon-file-power-point:before { content: '\e812'; } /* '' */
.icon-profile-picture:before { content: '\e813'; } /* '' */
.icon-students:before { content: '\e814'; } /* '' */
.icon-twitter:before { content: '\e815'; } /* '' */
.icon-file-unknown:before { content: '\e816'; } /* '' */
.icon-unlimited-access:before { content: '\e817'; } /* '' */
.icon-file-video:before { content: '\e818'; } /* '' */
.icon-file-word:before { content: '\e819'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-play-circled:before { content: '\f144'; } /* '' */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.rate-selector {
  direction: rtl;
  text-align: left;
}
.rate-selector label {
  display: inline-block;
  cursor: pointer;
}
.rate-selector label .icon-star {
  color: #d2d2d2;
}
.rate-selector input:hover + label .icon-star,
.rate-selector input:hover + label ~ label .icon-star,
.rate-selector input:checked + label .icon-star,
.rate-selector input:checked + label ~ label .icon-star {
  color: #31bdbf;
}
.rate-selector input {
  left: -9999px;
  position: absolute;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.radio {
  background-color: #f7f7f8;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  font-size: 1.143em;
  margin-bottom: 14px;
  padding: 10px 60px 10px 20px;
  position: relative;
  width: 100%;
}
.radio i {
  position: absolute;
  right: 20px;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
}
.radio i.unchecked {
  opacity: 1;
}
.radio input {
  left: -9999px;
  position: absolute;
}
.radio input:checked + i.checked {
  opacity: 1;
}
.radio input:checked + i.unchecked {
  opacity: 0;
}
.radio.disabled {
  cursor: default !important;
  opacity: 0.6;
}
.radio.invalid-answer,
.radio.valid-answer {
  color: #fff;
}
.radio.valid-answer {
  background-color: #52c41a;
}
.radio.invalid-answer {
  background-color: #f5222d;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.custom-select {
  width: 100%;
}
.custom-select .ant-select-selection {
  background-color: #fff;
  border: none;
  border-radius: 0;
  color: inherit;
  display: block;
  font-size: 1.143em;
  height: auto;
  padding: 7px 20px;
}
.custom-select .ant-select-selection__rendered {
  margin-left: 0;
}
.custom-select .ant-select-arrow {
  color: inherit;
  right: 25px;
}

.alert-message {
  margin-bottom: 20px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-close-x {
  height: 63px;
  line-height: 63px;
}
.ant-modal-header {
  padding: 20px 24px;
}
.ant-modal-title {
  font-size: 1.429em;
  font-weight: 500;
  padding-right: 10px;
}
@media only screen and (min-width: 576px) {
  .ant-modal-title {
    text-align: center;
  }
}
.ant-modal-body {
  padding: 0;
}
.ant-modal-body .container {
  padding: 20px;
}
@media only screen and (min-width: 576px) {
  .ant-modal-body .container {
    padding: 35px 40px;
  }
}
.custom-footer {
  border-top: 1px solid #e8e8e8;
  padding: 16px;
}
@media only screen and (min-width: 576px) {
  .custom-footer {
    padding: 20px 40px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.input {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
}
.input::-webkit-input-placeholder {
  color: #6a747d;
}
.input::-moz-placeholder {
  color: #6a747d;
}
.input:-ms-input-placeholder {
  color: #6a747d;
}
.input::-ms-input-placeholder {
  color: #6a747d;
}
.input::placeholder {
  color: #6a747d;
}
.action-btn {
  font-size: 1.286em;
  height: auto;
  padding-bottom: 8px;
  padding-top: 8px;
}
.footer-links {
  text-align: center;
}
@media only screen and (min-width: 576px) {
  .footer-links-space-between {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
}
.footer-links,
.footer-links button {
  color: #69767f;
  font-size: 1em;
  font-weight: 500;
}
.signup-btn {
  margin-bottom: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.lang-selector {
  margin-left: 10px;
}
.lang-selector .ant-select-selection {
  background-color: transparent;
  border: none;
  font-weight: 500;
  padding-right: 6px;
}
.lang-selector .ant-select-selection,
.lang-selector .ant-select-selection i {
  color: #a9a9ab;
}
.lang-selector__icon {
  margin-right: 10px;
  width: 20px !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header {
  background-color: #212529;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: auto;
  left: 0;
  padding: 0;
  position: fixed;
  right: 0;
  z-index: 10;
}
.header .ant-col {
  -webkit-transition: line-height 200ms ease-in-out;
  transition: line-height 200ms ease-in-out;
}
.header .menu {
  background-color: transparent;
  border-bottom-color: transparent;
}
@media only screen and (min-width: 576px) {
  .header .menu {
    float: right;
  }
}
.header .menu li {
  border-bottom: none;
  color: #fff;
  font-size: 1.143em;
  font-weight: 500;
  padding: 0 15px;
}
.header .menu li:hover {
  border-bottom: none;
}
.header .menu .ant-menu-submenu-title {
  font-size: 2.857em;
}
.header .menu .ant-menu-submenu {
  padding: 0;
}
.header .reset-button-padding {
  margin-right: -15px;
}
.header .header-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 8px 16px;
  -webkit-transition: padding 200ms ease-in-out;
  transition: padding 200ms ease-in-out;
}
.header .header-container li {
  position: relative;
}
.header .header-container li.active {
  color: #31bdbf;
}
@media only screen and (min-width: 576px) {
  .header .header-container li.active:after {
    background-color: #31bdbf;
    border-radius: 50%;
    bottom: -20px;
    content: '';
    height: 8px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: bottom 200ms ease-in-out;
    transition: bottom 200ms ease-in-out;
    width: 8px;
  }
}
.header img {
  width: 132px;
  -webkit-transition: width 200ms ease-in-out;
  transition: width 200ms ease-in-out;
}
.header.sticky .header-container {
  padding: 4px 16px;
}
.header.sticky .ant-col {
  line-height: 1em;
}
.header.sticky img {
  width: 110px;
}
.header.sticky li.active:after {
  bottom: -8px;
}
.float-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (min-width: 576px) {
  span.sign-in-btn {
    display: none;
  }
}
button.sign-in-btn {
  color: #fff;
  display: none;
}
@media only screen and (min-width: 576px) {
  button.sign-in-btn {
    display: inline-block;
  }
}
span.sign-out-btn {
  color: #fff;
  display: none;
}
@media only screen and (min-width: 576px) {
  span.sign-out-btn {
    display: inline-block;
  }
}
@media only screen and (min-width: 576px) {
  button.sign-out-btn {
    display: none;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
footer {
  background-color: #2d3f4f;
}
footer .container {
  opacity: 0.7;
}
@media only screen and (min-width: 1200px) {
  footer .container .row {
    display: flex;
    justify-content: center;
  }
}
footer .container .col {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 25px 0;
  text-align: center;
}
@media only screen and (min-width: 1200px) {
  footer .container .col {
    border-bottom: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    padding: 0 40px;
  }
}
footer .container .col:last-child {
  border-bottom: none;
}
@media only screen and (min-width: 1200px) {
  footer .container .col:last-child {
    border-right: none;
  }
}
footer img {
  display: inline-block;
}
@media only screen and (min-width: 1200px) {
  footer img {
    float: left;
    margin-bottom: 0;
    margin-right: 30px;
  }
}
footer p {
  font-size: 1.071em;
  margin-top: 10px;
}
@media only screen and (min-width: 1200px) {
  footer p {
    display: inline-block;
    margin: 0;
  }
}
footer p a,
footer p a:hover,
footer p a:visited,
footer p a:active {
  color: #fff;
}
footer .social-links {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
footer .social-links li {
  display: inline-block;
  padding: 0 10px;
}
footer .icon-container {
  color: #fff;
  display: inline-block;
  height: 40px;
  position: relative;
  width: 40px;
}
@media only screen and (min-width: 1200px) {
  footer .icon-container {
    height: 30px;
    width: 30px;
  }
}
footer .icon-container i {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 200ms color ease-in-out;
  transition: 200ms color ease-in-out;
}
@media only screen and (min-width: 1200px) {
  div.icon-container {
    float: left;
    margin-right: 30px;
  }
}
.copyright-section {
  background-color: #31bdbf;
  padding: 22px 0;
  text-align: center;
}
.copyright-section p {
  color: #fff;
  font-size: 1.071em;
  margin-bottom: 0;
}
.copyright-section span {
  cursor: pointer;
  text-decoration: underline;
}

.full-screen-loader {
  align-items: center;
  background-color: white;
  bottom: 0;
  display: flex;
  position: fixed;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(49, 189, 191, 0.2);
  border-right: 1.1em solid rgba(49, 189, 191, 0.2);
  border-bottom: 1.1em solid rgba(49, 189, 191, 0.2);
  border-left: 1.1em solid #31bdbf;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.backdrop {
  align-items: center;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  position: relative;
}
.backdrop .bg-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#212529), color-stop(10%, #212529), to(rgba(33, 37, 41, 0.6)));
  background: linear-gradient(to bottom, #212529 0%, #212529 10%, rgba(33, 37, 41, 0.6) 100%);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.backdrop .icon {
  bottom: 30px;
  color: #fff;
  font-size: 2em;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.backdrop .content {
  max-width: 860px;
  padding: 16px;
  width: 100%;
  z-index: 2;
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.breadcrumbs {
  left: 15px;
  position: absolute;
  top: 15px;
}
.breadcrumbs a,
.breadcrumbs span {
  color: #31bdbf;
}
.breadcrumbs span {
  font-size: 1.071em;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content-title-area .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 340px;
  max-width: 1200px;
  padding-bottom: 30px;
  position: relative;
}
.content-title-area h1 {
  color: #fff;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: 0.12em;
  line-height: 1.25em;
  margin-bottom: 0;
  margin-top: -10px;
}
@media only screen and (min-width: 576px) {
  .content-title-area h1 {
    font-size: 3.214em;
  }
}
.content-title-area .content-title-icon {
  color: #fff;
  font-size: 7.143em;
}
@media only screen and (min-width: 576px) {
  .content-title-area .content-title-icon {
    font-size: 9.286em;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content-container {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 80px;
  padding: 20px;
  position: relative;
}
@media only screen and (min-width: 576px) {
  .content-container {
    margin-bottom: 60px;
    padding: 40px;
  }
}
.content-container.top {
  margin-top: -285px;
  z-index: 2;
}

.tabs .ant-tabs-tab {
  color: #899299;
  font-size: 1.429em;
  font-weight: 500;
}
.tabs .ant-tabs-tab.ant-tabs-tab-active {
  color: #31bdbf;
}
.tabs .ant-tabs-ink-bar {
  bottom: 2px;
  height: 3px;
}
.tabs .ant-tabs-content {
  padding-top: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.wrapper {
  background-color: #f7f7f9;
  padding: 20px;
}
@media only screen and (min-width: 992px) {
  .wrapper {
    padding: 40px;
  }
}
.wrapper input {
  background-color: #fff;
}

.spinner {
  display: flex;
  justify-content: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.collapsible__container {
  border: none !important;
  background-color: #fff;
}
.collapsible__item {
  border: none !important;
  background-color: #f7f7f8;
  -webkit-transition: 200ms background-color ease-in-out;
  transition: 200ms background-color ease-in-out;
}
.collapsible__item:not(:last-child) {
  margin-bottom: 20px;
}
.collapsible__item .ant-collapse-header {
  color: #252c43;
  font-size: 1.286em;
  font-weight: 500;
  padding: 14px 25px !important;
  padding-right: 40px !important;
  -webkit-transition: 200ms color ease-in-out;
  transition: 200ms color ease-in-out;
}
.collapsible__item .ant-collapse-content {
  background-color: transparent;
  border-top: none;
  padding-left: 10px;
  padding-right: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
h2 {
  color: #334557;
  display: inline-block;
  font-size: 1.875em;
  font-weight: 600;
}
h3 {
  color: #334557;
  display: inline-block;
  font-size: 1.429em;
  font-weight: 600;
  line-height: 1.115em;
}
h4 {
  color: #707c89;
  display: inline-block;
  font-size: 1.071em;
}
.partial-underline {
  margin-bottom: 40px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .partial-underline {
    margin-bottom: 80px;
  }
}
.partial-underline::after {
  content: '';
  background-color: #31bdbf;
  bottom: -10px;
  display: block;
  height: 5px;
  position: absolute;
  width: 60px;
}
.center {
  text-align: center;
}
.center:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.inverted h2 {
  color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.price-container {
  display: block;
}
.price-container span {
  font-size: 1.071em;
}
.price-container span:last-child {
  color: #5d5d60;
  text-decoration: line-through;
}
.price-container span:first-child {
  color: #252c43;
  font-weight: 600;
  margin-right: 6px;
  text-decoration: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.category {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 240px;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .category {
    height: 180px;
  }
}
.category__content {
  align-items: center;
  background-color: rgba(21, 24, 33, 0.8);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 15px;
  -webkit-transition: 100ms background-color ease-in-out;
  transition: 100ms background-color ease-in-out;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  .category__content:hover {
    background-color: rgba(49, 189, 191, 0.95);
  }
}
.category__content:hover span,
.category__content:hover i {
  display: none;
}
.category__content i {
  color: #fff;
  font-size: 3.571em;
  margin-bottom: 20px;
}
.category__content h3 {
  color: #fff;
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 5px;
}
@media only screen and (min-width: 768px) {
  .category__content h3 {
    font-size: 1.063em;
  }
}
.category__content span {
  color: #fff;
  display: inline-block;
  font-size: 1.125em;
  overflow: hidden;
  -webkit-transition: 200ms height ease-in-out;
  transition: 200ms height ease-in-out;
}
@media only screen and (min-width: 992px) {
  .category__content span {
    height: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.stars {
  margin-right: -2px;
}
.stars:first-child {
  margin-right: -2px;
}
.filled-star {
  color: #31bdbf;
}
.unfilled-star {
  color: #d2d2d2;
}
.rating {
  margin-left: -2px;
}
.rating .ratings-count {
  color: #5d5d60;
  font-size: 1.071em;
  padding-left: 5px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.subject {
  background-color: #f1f1f2;
  cursor: pointer;
}
.subject:hover .subject__more-details {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.subject .thumb-container {
  background-color: #000;
  overflow: hidden;
  position: relative;
}
.subject .thumb-container:hover img {
  opacity: 1;
}
.subject .thumb-container img {
  display: block;
  height: 250px;
  object-fit: cover;
  object-position: center;
  opacity: 0.9;
  -webkit-transition: 200ms opacity ease-in-out;
  transition: 200ms opacity ease-in-out;
  width: 100%;
}
.subject .content {
  height: 135px;
  padding: 25px 20px;
}
@media only screen and (min-width: 576px) {
  .subject .content {
    height: 145px;
  }
}
.subject .content h3 {
  color: #252c43;
  font-size: 1.125em;
  font-weight: 500;
  margin-bottom: 20px;
}
.subject .rating {
  float: left;
}
.subject .price-container {
  text-align: right;
  position: relative;
}
.subject .price-container span {
  display: block;
}
.subject .price-container span:first-child {
  margin-right: 0;
  position: relative;
  top: auto;
}
.subject .price-container span:last-child {
  position: absolute;
  right: 0;
  top: -20px;
}
.subject__more-details {
  background-color: rgba(241, 241, 242, 0.9);
  bottom: 0;
  left: 0;
  padding: 15px;
  position: absolute;
  right: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: 200ms -webkit-transform ease-in-out;
  transition: 200ms -webkit-transform ease-in-out;
  transition: 200ms transform ease-in-out;
  transition: 200ms transform ease-in-out, 200ms -webkit-transform ease-in-out;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.course {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}
.course .course-header {
  border-bottom: 1px solid #e9e8ed;
  min-height: 128px;
  padding: 22px 40px;
}
.course .course-header h5 {
  color: #848e97;
  font-size: 1.071em;
  line-height: 20px;
  margin-bottom: 0;
}
.course .course-header h3 {
  color: #334559;
  font-size: 1.429em;
  line-height: 1em;
  margin-bottom: 0;
}
.course .course-header .course-icon {
  color: #334559;
  font-size: 1.571em;
  position: absolute;
  right: 22px;
  top: 8px;
}
.course .course-body {
  display: flex;
  flex-direction: column;
  height: 215px;
  justify-content: space-between;
  padding: 30px 40px 40px 40px;
}
.course .course-body p {
  color: #54545c;
  font-weight: 500;
  line-height: 1.2em;
  margin-bottom: 30px;
}
.course .course-body button {
  height: auto;
  padding-bottom: 8px;
  padding-top: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.course-header h2 {
  margin-bottom: 2px;
}
.course-header .partial-underline {
  margin-bottom: 10px;
}
.course-header .partial-underline:after {
  bottom: -2px;
}
@media only screen and (min-width: 576px) {
  .course-header .course-pricing {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.course-header .course-pricing button {
  width: 100%;
}
@media only screen and (min-width: 576px) {
  .course-header .course-pricing button {
    width: auto;
  }
}
.course-header .course-pricing .price-container {
  margin-bottom: 10px;
}
@media only screen and (min-width: 576px) {
  .course-header .course-pricing .price-container {
    margin-bottom: 0;
  }
}
.course-header .course-pricing .price-container span:last-child {
  font-size: 1.286em;
}
.course-header .course-pricing .price-container span:first-child {
  font-size: 1.786em;
}
@media only screen and (min-width: 576px) {
  .course-header .course-subheader {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.course-header .ant-divider {
  margin: 20px 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.purchase-btn__modal__icon {
  margin-right: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.duration {
  background-color: #31bdbf;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 1.071em;
  padding: 1px 6px;
}
.duration.float {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media only screen and (min-width: 1200px) {
  .duration.float {
    display: none;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.subject-moredetails__content h3,
.subject-moredetails__content h4 {
  display: block;
}

.video-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
.video {
  width: 100%;
}
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
.fullscreen-button {
  position: absolute;
  top: 2%;
  left: 90%;
  z-index: 10;
}
.watermark {
  position: absolute;
  color: #0000005c;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  opacity: 1;
  -webkit-transition: 0.5s opacity linear;
  transition: 0.5s opacity linear;
}
.invisible {
  opacity: 0;
}
.fullscreen-button {
  position: static;
  position: initial;
  margin-bottom: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.video-container {
  align-items: center;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.video-container__icon {
  color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.course-header {
  margin-bottom: 20px;
}
.super-stage-container {
  margin-bottom: 20px !important;
}
.super-stage-container .super-stage .thumb-container {
  background-color: #000;
  overflow: hidden;
  position: relative;
}
.super-stage-container .super-stage .thumb-container img {
  display: block;
  height: 250px;
  object-fit: cover;
  object-position: center;
  opacity: 0.9;
  width: 100%;
}
.super-stage-container .super-stage .content {
  padding: 20px;
  background-color: #f1f1f2;
}
.super-stage-container .super-stage .content h4 {
  color: #000;
}
@media only screen and (min-width: 1200px) {
  .super-stage-container .super-stage .content {
    padding: 30px;
  }
}
.super-stage-container .super-stage .content .summary {
  margin-top: 10px;
  margin-bottom: 10px;
}
.super-stage-container .super-stage .rating {
  margin-bottom: 10px;
}
.super-stage-container .super-stage .btns-container button {
  margin-bottom: 10px;
}
.super-stage-container .super-stage .btns-container .ant-col:nth-child(1) button {
  padding-inline: 0;
}
.reviews-panel {
  background-color: #fff;
}
.reviews-panel .review-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.2rem;
}
.reviews-panel .reviews-container {
  max-height: 70dvh;
  overflow-y: scroll;
}
.reviews-panel .reviews-container::-webkit-scrollbar {
  width: 5px;
}
.reviews-panel .reviews-container::-webkit-scrollbar-track {
  background: #f7f7f8;
}
.reviews-panel .reviews-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
.reviews-panel .review-item {
  background-color: #f7f7f8;
  margin-bottom: 5px;
  padding: 15px 25px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.subject-item {
  background-color: #f7f7f8;
  cursor: pointer;
}
.subject-item:not(:last-child) {
  margin-bottom: 20px;
}
.subject-item img {
  display: block;
  height: 204px;
  object-fit: cover;
  object-position: center;
  opacity: 0.9;
  width: 100%;
}
.subject-item .img-container {
  background-color: #000;
}
.subject-item .content {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (min-width: 1200px) {
  .subject-item .content {
    padding: 30px;
  }
}
.subject-item .content h3 {
  font-size: 1.286em;
  font-weight: 500;
}
.subject-item .content p {
  line-height: 1.25em;
}
@media only screen and (min-width: 1200px) {
  .subject-item .content p {
    margin-bottom: 0;
  }
}
.subject-item .content.side {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: right;
}
.subject-item .content.side > * {
  align-self: flex-end;
  margin-bottom: 10px;
}
.subject-item .content .free-trial-hours {
  margin-top: 1rem;
  font-weight: 600;
  color: red;
}
.subject-item .pricing {
  position: absolute;
  right: 30px;
  top: 30px;
}
.subject-item .pricing span {
  font-size: 1em;
}
.subject-item .rating {
  margin-bottom: 10px;
}
.subject-item button {
  background-color: #334557;
  border-color: #334557;
  font-size: 1.143em;
  width: 100%;
}
@media only screen and (min-width: 1200px) {
  .subject-item button {
    width: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .subject-item .mobile {
    display: none;
  }
}
.price-wrapper {
  padding-bottom: 30px;
  top: 5px;
}
@media (max-width: 1200px) {
  .course-hours {
    display: none;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.my-courses__stage {
  margin-bottom: 30px;
}
.my-courses__stage h4 {
  color: #212529;
  margin-bottom: 10px;
}
.my-courses__stage .subject-item {
  background-color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.installment__item {
  background-color: #fff;
  padding: 20px 30px;
}
@media only screen and (min-width: 768px) {
  .installment__item {
    display: flex;
    justify-content: space-between;
  }
}
.installment__item:not(:last-child) {
  margin-bottom: 5px;
}
.installment__item > div {
  flex-grow: 0;
}
.installment button {
  width: 100%;
}
.installment h4,
.installment p {
  margin-bottom: 4px;
}
.installment__price {
  font-size: 1.143em;
  font-weight: 500;
}
@media only screen and (min-width: 768px) {
  .installment__price {
    text-align: right;
  }
}
.installment__ref span {
  font-weight: 500;
}
.installment__status {
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  padding: 1px 10px;
}
.installment__status.waiting {
  background-color: #334557;
}
.installment__status.approved {
  background-color: #31bdbf;
}
.items-list:not(:last-child) {
  margin-bottom: 20px;
}
.items-list__header h4 {
  color: #212529;
  margin-bottom: 10px;
}
.items-list__header.floatItem {
  float: right;
}
.floatItem {
  float: right;
  text-align: end;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.landing {
  min-height: 100vh;
  padding-bottom: 90px;
}
@media only screen and (min-width: 480px) {
  .landing .content {
    text-align: left;
  }
}
.landing .content h1,
.landing .content h3 {
  color: #fff;
}
.landing .content h1 {
  font-size: 3.571em;
  line-height: 1.115em;
  margin-bottom: 35px;
}
@media only screen and (min-width: 576px) {
  .landing .content h1 {
    font-size: 4.188em;
  }
}
.landing .content h3 {
  font-size: 1.429em;
  font-weight: 400;
  line-height: 1.25em;
  margin-bottom: 25px;
}
@media only screen and (min-width: 576px) {
  .landing .content h3 {
    font-size: 1.563em;
  }
}
.landing .content button {
  display: inline-block;
  font-size: 1.125em;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.features {
  background-color: #f0f0f1;
}
@media only screen and (min-width: 992px) {
  .features .features-container {
    display: flex;
    justify-content: space-around;
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 992px) {
  .features .feature-item {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 25px;
  }
}
.features .feature-item__icon {
  display: inline-block;
  flex-shrink: 0;
  width: 70px;
}
.features .feature-item__icon i {
  color: #31bdbf;
  font-size: 3.929em;
}
.features .feature-item-container {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 30px 10px;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  .features .feature-item-container {
    display: flex;
    margin-bottom: 0;
    text-align: left;
  }
}
@media only screen and (min-width: 992px) {
  .features .feature-item-container .feature-content {
    border-left: 1px solid #e9e9ec;
    margin-left: 16px;
    min-height: 112px;
    padding-left: 16px;
  }
}
.features .feature-item-container img {
  display: inline-block;
  margin-bottom: 20px;
  width: 80px;
}
@media only screen and (min-width: 992px) {
  .features .feature-item-container img {
    flex-shrink: 0;
    margin-bottom: 0;
    width: 60px;
  }
}
.features .feature-item-container h3 {
  font-size: 1.438em;
}
@media only screen and (min-width: 992px) {
  .features .feature-item-container h3 {
    margin-bottom: 5px;
  }
}
.features .feature-item-container p {
  margin: 0;
}
@media only screen and (min-width: 992px) {
  .features .feature-item-container p {
    font-size: 0.875em;
    font-weight: 500;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.popular-learning-path {
  background-color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.statistics {
  background-position: center;
  background-size: cover;
  padding: 20px 0;
  position: relative;
}
@media only screen and (min-width: 992px) {
  .statistics {
    padding: 120px 0;
  }
}
.statistics:before {
  background-color: rgba(54, 180, 183, 0.95);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.statistics .container {
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 992px) {
  .statistics .container {
    display: flex;
    justify-content: space-around;
  }
}
.statistic-item {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 60px;
}
@media only screen and (min-width: 992px) {
  .statistic-item {
    flex-direction: row;
    margin-bottom: 0;
  }
}
.statistic-item:last-child {
  margin-bottom: 0;
}
.statistic-item .icon-container {
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) {
  .statistic-item .icon-container {
    margin-right: 20px;
  }
}
.statistic-item .icon {
  color: #fff;
  font-size: 9em;
}
.statistic-item .content {
  text-align: center;
}
.statistic-item .content h3,
.statistic-item .content h4 {
  color: #fff;
  margin-bottom: 0;
}
.statistic-item .content h3 {
  font-size: 3.75em;
  line-height: 1em;
}
.statistic-item .content h4 {
  font-size: 1.438em;
  font-weight: 600;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.popular-subjects {
  background-color: #fff;
}
.popular-subjects__popover-content h3,
.popular-subjects__popover-content h4 {
  display: block;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.why-us-section {
  background-color: #334557;
}
.why-us-section .content {
  color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.courses-list {
  background-color: #f0f0f2;
  padding: 60px 0;
}
.courses-list .category-item:not(:last-child) {
  margin-bottom: 80px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.contact {
  background-color: #f0f0f2;
  padding: 60px 0;
}
.contact__row {
  align-items: stretch;
}
.contact__container {
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
}
@media only screen and (min-width: 576px) {
  .contact__container {
    padding: 20px;
  }
}
.contact__content {
  background-color: #f0f0f1;
  height: 100%;
  padding: 20px;
}
@media only screen and (min-width: 576px) {
  .contact__content {
    padding: 30px;
  }
}
.contact .input {
  background-color: #fff;
}
.contact .address,
.contact .contact-info {
  display: flex;
}
.contact .address > div,
.contact .contact-info > div {
  padding-left: 10px;
}
.contact .contact-icon {
  color: #334657;
  margin-left: -5px;
}
.contact h4 {
  color: #334657;
  font-size: 1.143em;
  margin-top: 10px;
}
.contact p {
  font-size: 0.929em;
  line-height: 1.75em;
  margin-bottom: 0;
}
.contact p a {
  color: #717179;
}
.contact .divider {
  background-color: #e2e1e6;
}
.contact__map {
  background-position: center;
  height: 400px;
  margin-top: 20px;
  width: 100%;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.contact {
  background-color: #f0f0f2;
  padding: 60px 0;
}
.contact__row {
  align-items: stretch;
}
.contact__container {
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
}
@media only screen and (min-width: 576px) {
  .contact__container {
    padding: 20px;
  }
}
.contact__content {
  background-color: #f0f0f1;
  height: 100%;
  padding: 20px;
}
@media only screen and (min-width: 576px) {
  .contact__content {
    padding: 30px;
  }
}
.contact .input {
  background-color: #fff;
}
.contact .address,
.contact .contact-info {
  display: flex;
}
.contact .address > div,
.contact .contact-info > div {
  padding-left: 10px;
}
.contact .contact-icon {
  color: #334657;
  margin-left: -5px;
}
.contact h4 {
  color: #334657;
  font-size: 1.143em;
  margin-top: 10px;
}
.contact p {
  font-size: 0.929em;
  line-height: 1.75em;
  margin-bottom: 0;
}
.contact p a {
  color: #717179;
}
.contact .divider {
  background-color: #e2e1e6;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.about-us-section {
  background-color: #f0f0f1;
  padding-bottom: 60px;
}
@media only screen and (min-width: 576px) {
  .about-us-section {
    padding-bottom: 80px;
  }
}
.about-us-section__content {
  align-items: stretch;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
}
@media only screen and (min-width: 576px) {
  .about-us-section__content {
    padding: 16px;
  }
}
.about-us-section img {
  width: 100%;
}
.about-us-section__desc {
  background-color: #f0f0f1;
  height: 100%;
  padding: 20px;
  text-align: center;
}
@media only screen and (min-width: 576px) {
  .about-us-section__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    text-align: left;
  }
}
.lecturers-section {
  background-color: #fff;
}
.lecturers-section .slick-prev::before,
.lecturers-section .slick-next::before {
  color: #5d5d60;
}
.lecturers-section .container {
  padding: 0 60px;
}
.lecturers-section .slide-item {
  padding: 20px;
}
.lecturers-section .slide-item__header,
.lecturers-section .slide-item__footer {
  background-color: #f0f0f2;
  text-align: center;
}
.lecturers-section .slide-item__header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 1px;
  padding: 40px 0;
}
.lecturers-section .slide-item__footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px 10px;
}
.lecturers-section .slide-item__footer span {
  display: block;
}
.lecturers-section .slide-item__name {
  color: #2d3f4f;
  font-size: 1.286em;
  line-height: 1em;
}
.lecturers-section .slide-item__specialty {
  color: #7e8790;
}
.lecturers-section .slide-item .lecturer-img {
  background-position: center;
  background-size: cover;
  border: 6px solid #fff;
  border-radius: 100%;
  margin: 0 auto;
  width: 130px;
}
.lecturers-section .slide-item .lecturer-img:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.lecturers-section p {
  font-size: 1.143em;
  font-weight: 500;
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.intro-container {
  margin-bottom: 40px;
}
.intro-container h4 {
  margin-bottom: 2px;
}
.intro-container h3 {
  margin-bottom: 0;
}
.intro-container .video-container {
  background-position: center;
  background-size: cover;
  aspect-ratio: 1.77777778;
  max-height: 400px;
  margin-bottom: 30px;
  width: 100%;
}
.super-stage-container {
  padding: 15px;
  background-color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.stages-container .container .stage-container {
  border-radius: 0;
}
@media only screen and (min-width: 992px) {
  .stages-container .container .stage-container {
    padding: 80px 110px;
  }
}
.stages-container .container:first-child .stage-container {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.stages-container .container:last-child .stage-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.stages-container .container:not(:last-child) .stage-container {
  margin-bottom: 5px;
}
.stages-container .container .subject-item:not(:last-child) {
  margin-bottom: 20px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.subject-overview {
  background-color: #f7f7f8;
  padding: 20px;
}
.subject-overview .overview-item:not(:last-child) {
  border-bottom: 1px solid #e7e7ea;
  margin-bottom: 10px;
}
.subject-overview .overview-item h4 {
  color: #252c43;
  font-size: 1.286em;
  line-height: 1em;
  margin-bottom: 6px;
}
.subject-overview .overview-item p {
  color: #52555d;
  margin-bottom: 10px;
}
.subject-overview .overview-item .social-links button {
  margin-right: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.syllabus {
  margin-top: 30px;
}
.syllabus__container {
  border: none !important;
  background-color: #fff;
}
.syllabus__item {
  border: none !important;
  background-color: #f7f7f8;
}
.syllabus__item:not(:last-child) {
  margin-bottom: 20px;
}
.syllabus__item .ant-collapse-header {
  color: #252c43;
  font-size: 1.286em;
  font-weight: 500;
  padding: 14px 25px !important;
  padding-right: 40px !important;
}
.syllabus__item .ant-collapse-content {
  background-color: transparent;
  border-top: 1px solid #fff;
  padding-left: 10px;
  padding-right: 10px;
}
.syllabus .resource-item {
  align-items: center;
  color: #52555d;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
}
.syllabus .resource-item.disabled {
  opacity: 0.6;
}
.syllabus .resource-item i {
  font-size: 1.8em;
}
.syllabus .resource-item span {
  display: inline-block;
  padding-left: 10px;
}
.syllabus .syllabus-unavailable {
  text-align: center;
}
.language-selector__container {
  display: flex;
  justify-content: flex-end;
}
.language-selector__container .custom-select {
  width: auto;
}
.language-selector__container .ant-select-selection {
  background-color: #f0f0f2;
}
.preview-tag {
  margin-left: auto;
  background-color: #31bdbf;
  color: white;
  text-align: center;
  font-weight: 600;
  padding: 0.3rem 1.4rem !important;
  border-radius: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.meeting-indicator {
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: #f0f0f1;
  padding: 1rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.meeting-indicator p {
  font-size: x-large;
  text-align: center;
}
.meeting-indicator a {
  background-color: #31bdbf;
  color: white;
  padding: 0.5rem 4rem;
  border-radius: 8px;
  margin-inline: auto;
  font-size: 1.2rem;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.lecturer-item {
  background-color: #f7f7f8;
  margin-bottom: 2px;
  padding: 30px;
  text-align: center;
}
@media only screen and (min-width: 576px) {
  .lecturer-item {
    display: flex;
    text-align: left;
  }
}
.lecturer-item img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 100px;
  margin-bottom: 20px;
  object-fit: cover;
  object-position: center;
  width: 100px;
}
@media only screen and (min-width: 576px) {
  .lecturer-item img {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 576px) {
  .lecturer-item__content {
    padding-left: 30px;
    padding-top: 5px;
  }
}
.lecturer-item h4 {
  color: #252c43;
  font-size: 1.286em;
  line-height: 1em;
  margin-bottom: 6px;
}
.lecturer-item h5 {
  color: #8f99a3;
  font-size: 1.071em;
}
.lecturer-item p {
  color: #52555d;
  margin-bottom: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.no-reviews {
  background-color: #f7f7f8;
  padding: 20px;
  text-align: center;
}
.review-item {
  background-color: #f7f7f8;
  margin-bottom: 2px;
  padding: 25px;
}
@media only screen and (min-width: 992px) {
  .review-item {
    padding: 45px 42px;
  }
}
.review-item__container {
  margin-bottom: 50px;
  position: relative;
}
.review-item__loader {
  align-items: center;
  background-color: rgba(247, 247, 248, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.review-item__time {
  font-size: 0.75em;
}
.review-item h4 {
  color: #252c43;
  font-size: 1.286em;
  line-height: 1em;
  margin-bottom: 6px;
}
.review-item p {
  color: #52555d;
  margin-bottom: 10px;
}
.review-pagination__container {
  display: flex;
  justify-content: center;
}
.review-pagination .ant-pagination-disabled {
  opacity: 0.4;
}
.review-pagination .ant-pagination-item {
  background-color: #efeff1;
  border: none;
  border-radius: 50%;
  color: #334657;
}
.review-pagination .ant-pagination-item-active {
  background-color: #31bdbf;
  color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.review-container {
  background-color: #f7f7f8;
  padding: 20px;
}
.review-container.no-review {
  text-align: center;
}
.review-container textarea {
  background-color: #fff;
}
.review-container .form-item:last-child {
  margin-bottom: 0;
}
.review-container h4 {
  color: #252c43;
  display: block;
  font-size: 1.286em;
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.lecturer-item {
  background-color: #f7f7f8;
  margin-bottom: 2px;
  padding: 30px;
  text-align: center;
}
@media only screen and (min-width: 576px) {
  .lecturer-item {
    display: flex;
    text-align: left;
  }
}
.lecturer-item img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 100px;
  margin-bottom: 20px;
  object-fit: cover;
  object-position: center;
  width: 100px;
}
@media only screen and (min-width: 576px) {
  .lecturer-item img {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 576px) {
  .lecturer-item__content {
    padding-left: 30px;
    padding-top: 5px;
  }
}
.lecturer-item h4 {
  color: #252c43;
  font-size: 1.286em;
  line-height: 1em;
  margin-bottom: 6px;
}
.lecturer-item h5 {
  color: #8f99a3;
  font-size: 1.071em;
}
.lecturer-item p {
  color: #52555d;
  margin-bottom: 0;
}
.content-unavailable {
  background-color: #f7f7f8;
  padding: 20px;
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.related-subjects__section {
  background-color: #fff;
}
.related-subjects__section .heading {
  margin-bottom: 40px;
}
.related-subjects__slide-item {
  width: 33.33%;
  overflow: hidden;
  padding: 0 15px;
}
.related-subjects__slider-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.related-subjects__slider-controls button:not(:last-child) {
  margin-right: 60px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.subject-detail-container .subject-header {
  margin-bottom: 20px;
}
.subject-detail-container .subject-header .ant-divider {
  display: none;
}
.subject-detail-container .video-container {
  align-items: center;
  background-position: center;
  background-size: cover;
  display: flex;
  aspect-ratio: 1.77777778;
  max-height: 400px;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
}
.subject-detail-container .video-container__icon {
  color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.question {
  background-color: #fff;
  padding: 20px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mcq__container .partial-underline {
  margin-bottom: 40px;
}
.mcq__footer {
  padding-top: 20px;
}
.mcq-not-answered {
  background-color: #f06269;
}
.mcq-not-answered .ant-collapse-header {
  color: #fff !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.inner-wrapper {
  background-color: #f7f7f9;
  padding: 20px;
}
@media only screen and (min-width: 992px) {
  .inner-wrapper {
    padding: 40px;
  }
}
.inner-wrapper input {
  background-color: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.intro-container {
  margin-bottom: 40px;
}
.intro-container h4 {
  margin-bottom: 2px;
}
.intro-container h3 {
  margin-bottom: 0;
}
.intro-container .video-container {
  background-position: center;
  background-size: cover;
  height: 200px;
  margin-bottom: 30px;
  width: 100%;
}
.purchase-wrapper:not(:last-child) {
  margin-bottom: 1px;
}
.purchase-wrapper label {
  background-color: #fff;
}
.purchase-summary {
  height: 100%;
}
.purchase-summary h4 {
  color: #334557;
}
.purchase-summary__table {
  display: flex;
  justify-content: space-between;
}
.purchase-summary__table p {
  margin-bottom: 0;
}
.purchase-summary__table p:first-child {
  color: #334557;
  font-weight: 500;
}
.next-btn {
  float: right;
  margin-top: 20px;
  min-width: 200px;
}
.affiliation-btn {
  text-align: right;
}
.section-labels {
  color: #334557;
  font-weight: 500;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.bank-details {
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 40px;
  width: 100%;
}
.bank-details ul {
  list-style: none;
  padding: 0;
}
.bank-details ul li:not(:last-child) {
  margin-bottom: 10px;
}
.bank-details ul li:first-child {
  color: #252c43;
  font-weight: 500;
  margin-bottom: 15px;
}
.bank-details ul span {
  font-weight: 500;
}

.complete-purchase h3 {
  margin-bottom: 20px;
}
.complete-purchase button {
  margin-top: 0;
}
.complete-purchase .bank-details {
  margin-bottom: 20px;
}

.complete-purchase h3 {
  margin-bottom: 20px;
}
.complete-purchase button {
  margin-top: 0;
}
.complete-purchase .bank-details {
  margin-bottom: 20px;
}

.section-container .ant-result-title {
  line-height: 1.2;
  margin-bottom: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.about-us-section {
  background-color: #f0f0f1;
  padding-bottom: 60px;
}
@media only screen and (min-width: 576px) {
  .about-us-section {
    padding-bottom: 80px;
  }
}
.about-us-section__content {
  align-items: stretch;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
}
@media only screen and (min-width: 576px) {
  .about-us-section__content {
    padding: 16px;
  }
}
.about-us-section img {
  width: 100%;
}
.about-us-section__desc {
  background-color: #f0f0f1;
  height: 100%;
  padding: 20px;
  text-align: center;
}
.about-us-section__desc a {
  margin-top: 2rem;
  font-size: 1.286em;
  font-weight: 500;
  height: auto;
  padding: 2px 16px;
}
@media only screen and (min-width: 576px) {
  .about-us-section__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    text-align: left;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.course-header {
  margin-bottom: 20px;
}
.super-stage-container {
  margin-bottom: 20px !important;
}
.super-stage-item {
  background-color: #f7f7f8;
  cursor: pointer;
}
.super-stage-item .img-container {
  background-color: #000;
  max-height: 400px;
}
.super-stage-item .img-container img {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.9;
  width: 100%;
}
.super-stage-item .content {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;
}
@media only screen and (min-width: 1200px) {
  .super-stage-item .content {
    padding: 30px;
  }
}
.super-stage-item .rating {
  margin-bottom: 10px;
}
.super-stage-item button {
  background-color: #334557;
  border-color: #334557;
  font-size: 1.143em;
  width: 100%;
}
@media only screen and (min-width: 1200px) {
  .super-stage-item button {
    width: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .super-stage-item .mobile {
    display: none;
  }
}

